import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 651.000000 651.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,651.000000) scale(0.100000,-0.100000)">

<path d="M1980 3706 l0 -396 55 0 56 0 -3 393 -3 392 -52 3 -53 3 0 -395z"/>
<path d="M1427 4070 c-101 -39 -157 -111 -157 -201 0 -57 35 -127 78 -156 18
-12 103 -45 188 -73 171 -57 192 -73 180 -132 -13 -64 -71 -98 -167 -98 -68 0
-134 26 -179 71 -16 16 -32 29 -37 29 -4 0 -25 -13 -46 -29 l-38 -29 44 -45
c127 -128 342 -144 464 -33 53 49 76 115 63 185 -17 91 -63 125 -257 186 -79
26 -153 55 -164 66 -21 21 -26 78 -8 111 17 32 86 58 159 58 72 0 124 -19 178
-65 l33 -28 32 29 c18 16 36 32 41 36 9 7 -48 61 -95 90 -80 49 -226 62 -312
28z"/>
<path d="M2231 4044 c-12 -15 -21 -34 -21 -44 0 -10 9 -29 21 -44 43 -55 138
-15 123 53 -14 61 -86 82 -123 35z"/>
<path d="M2680 3824 c-25 -14 -51 -32 -57 -41 -11 -14 -13 -12 -13 20 l0 37
-55 0 -55 0 0 -265 0 -265 55 0 55 0 0 160 c0 138 3 166 19 198 49 95 181 100
225 7 13 -26 16 -68 16 -199 l0 -166 54 0 54 0 4 169 c3 167 4 168 32 206 65
85 174 77 222 -17 10 -20 14 -70 14 -193 l0 -165 56 0 56 0 -3 183 -4 182 -31
55 c-76 135 -244 159 -349 50 l-28 -30 -35 36 c-67 69 -153 83 -232 38z"/>
<path d="M3710 3833 c-19 -8 -50 -29 -69 -46 l-33 -32 4 43 4 42 -53 0 -53 0
0 -375 0 -375 55 0 55 0 0 146 0 146 33 -28 c47 -39 89 -54 154 -54 96 1 177
52 226 144 32 59 31 203 0 262 -29 54 -101 117 -151 132 -53 16 -129 14 -172
-5z m151 -108 c16 -9 43 -34 59 -57 77 -105 1 -258 -128 -258 -159 0 -234 181
-120 287 47 44 133 56 189 28z"/>
<path d="M4250 3838 c-30 -5 -59 -13 -63 -17 -4 -4 -2 -29 5 -54 l12 -47 41 8
c22 4 81 7 132 7 83 0 94 -2 117 -24 14 -14 26 -35 26 -47 0 -22 -3 -22 -137
-26 -153 -4 -184 -14 -224 -70 -34 -47 -37 -131 -7 -182 58 -100 224 -117 330
-35 20 16 39 29 42 29 3 0 6 -16 6 -35 l0 -35 50 0 50 0 0 195 c0 213 -5 239
-56 287 -54 50 -190 70 -324 46z m270 -327 c0 -23 -49 -66 -97 -86 -47 -20
-132 -19 -161 1 -26 18 -29 60 -5 86 14 16 33 18 140 18 114 0 123 -1 123 -19z"/>
<path d="M2230 3575 l0 -265 55 0 55 0 0 265 0 265 -55 0 -55 0 0 -265z"/>
<path d="M4680 3832 c0 -2 57 -116 126 -253 l126 -250 -46 -114 c-25 -63 -46
-117 -46 -120 0 -3 27 -5 60 -5 33 0 60 4 60 9 0 5 68 172 150 372 83 199 150
364 150 366 0 1 -26 3 -57 3 l-58 0 -68 -178 c-38 -98 -72 -184 -77 -191 -5
-8 -43 55 -106 173 -94 177 -100 186 -129 187 -16 0 -42 2 -57 3 -16 1 -28 0
-28 -2z"/>
<path d="M4860 3831 c0 -12 110 -141 120 -141 7 0 94 105 114 138 6 9 -20 12
-113 12 -67 0 -121 -4 -121 -9z"/>
<path d="M4670 2916 c-19 -7 -42 -16 -51 -20 -10 -5 -19 -1 -22 8 -4 9 -13 16
-22 16 -13 0 -15 -25 -15 -180 l0 -180 25 0 25 0 0 116 c0 162 24 204 115 204
81 0 95 -28 95 -195 l0 -125 25 0 25 0 0 128 c0 139 -11 179 -60 214 -34 24
-98 31 -140 14z"/>
<path d="M5030 2908 c-122 -62 -122 -274 0 -335 57 -30 98 -29 144 2 l36 24 0
-44 c0 -64 -37 -105 -93 -105 -23 0 -61 9 -84 20 -43 19 -43 19 -54 -2 -14
-25 -5 -32 68 -54 83 -24 137 -7 187 60 19 25 21 44 24 237 3 206 3 209 -18
209 -11 0 -23 -7 -26 -15 -5 -12 -12 -11 -42 5 -48 26 -88 25 -142 -2z m139
-49 c31 -29 41 -61 41 -127 0 -89 -36 -132 -109 -132 -75 1 -111 44 -111 132
0 103 33 148 110 148 33 0 53 -6 69 -21z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
